// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .border-bottom {
    border-bottom: 0.0625rem solid rgba(201, 204, 207, 1) !important;
}
.text12 {
    font-size: 12px;
}
.link {
    text-decoration: none;
    font-size: 13px;
    transition: 0.3s;
}
.paymentIcon {
    width: 60px;
}
.subscriptionPlan .link {
    color: #4789c6;
}


@media (min-width: 768px) and (max-width: 991px) {
.vcmonthly-subscription span.fs-3 {
    font-size: 18px !important;
}
    
}`, "",{"version":3,"sources":["webpack://./src/pages/Merchant/subscription/subscriptiondetail.css"],"names":[],"mappings":"AAAA;IACI,gEAAgE;AACpE;AACA;IACI,eAAe;AACnB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,cAAc;AAClB;;;AAGA;AACA;IACI,0BAA0B;AAC9B;;AAEA","sourcesContent":["body .border-bottom {\n    border-bottom: 0.0625rem solid rgba(201, 204, 207, 1) !important;\n}\n.text12 {\n    font-size: 12px;\n}\n.link {\n    text-decoration: none;\n    font-size: 13px;\n    transition: 0.3s;\n}\n.paymentIcon {\n    width: 60px;\n}\n.subscriptionPlan .link {\n    color: #4789c6;\n}\n\n\n@media (min-width: 768px) and (max-width: 991px) {\n.vcmonthly-subscription span.fs-3 {\n    font-size: 18px !important;\n}\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
