// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backBtn {
    border: 1px solid rgba(186, 191, 195, 1);
    padding: 0 0.2rem;
    border-radius: 0.2rem;
    font-size: 2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    color: rgba(109, 113, 117, 1);
    transition: 0.3s;
}
.backBtn:hover {
    background: #fff;
}
.chooseCard {
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
}
.chooseCard form {
    background-color: #ebf5fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 1rem 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.chooseCard .text-end a {
    text-decoration: none;
    color: rgba(44, 110, 203, 1);
    transition: 0.3s;
}
.chooseCard .text-end a:hover {
    text-decoration: underline;
}
.fw-500 {
    font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Merchant/subscription/subscriptionplancreate.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,iBAAiB;IACjB,qBAAqB;IACrB,eAAe;IACf,qBAAqB;IACrB,sBAAsB;IACtB,oBAAoB;IACpB,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,yBAAyB;IACzB,4BAA4B;IAC5B,6BAA6B;AACjC;AACA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;AACpB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".backBtn {\n    border: 1px solid rgba(186, 191, 195, 1);\n    padding: 0 0.2rem;\n    border-radius: 0.2rem;\n    font-size: 2rem;\n    display: inline-block;\n    vertical-align: middle;\n    margin-right: 0.5rem;\n    color: rgba(109, 113, 117, 1);\n    transition: 0.3s;\n}\n.backBtn:hover {\n    background: #fff;\n}\n.chooseCard {\n    border-radius: 0.375rem;\n    border: 1px solid #dee2e6;\n}\n.chooseCard form {\n    background-color: #ebf5fa;\n    border-bottom: 1px solid #dee2e6;\n    padding: 1rem 1rem 1.5rem;\n    border-bottom-left-radius: 0;\n    border-bottom-right-radius: 0;\n}\n.chooseCard .text-end a {\n    text-decoration: none;\n    color: rgba(44, 110, 203, 1);\n    transition: 0.3s;\n}\n.chooseCard .text-end a:hover {\n    text-decoration: underline;\n}\n.fw-500 {\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
