// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalCard .form-control, .modalCard .form-select {
    font-size: 14px;
}
.modalCard .bi-info-circle-fill {
    font-size: 12px;
    margin-left: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/merchant/billing/addpaymentmethod.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".modalCard .form-control, .modalCard .form-select {\n    font-size: 14px;\n}\n.modalCard .bi-info-circle-fill {\n    font-size: 12px;\n    margin-left: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
